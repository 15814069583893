import { Dispatch, SetStateAction } from 'react';
import { ChatMessage, MessageRole } from 'store/chat/interfaces';

async function readStream(
  reader: ReadableStreamDefaultReader<Uint8Array>,
  setMessages: Dispatch<SetStateAction<ChatMessage[]>>,
  scrollToBottom: () => void,
) {
  const decoder = new TextDecoder('utf-8');
  let decodedMessage = '';

  const processChunk = async () => {
    const { done, value } = await reader.read();

    if (done) {
      if (decodedMessage.trim()) {
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1].content = decodedMessage;
          return updatedMessages;
        });
        scrollToBottom();
      }
      return;
    }

    const chunk = decoder.decode(value, { stream: true });
    decodedMessage += chunk;

    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      updatedMessages[updatedMessages.length - 1].content += chunk;
      return updatedMessages;
    });

    scrollToBottom();
    await processChunk();
  };

  setMessages((prevMessages) => [
    ...prevMessages,
    { role: MessageRole.Assistant, content: '' },
  ]);

  await processChunk();
}

export default readStream;
