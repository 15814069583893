import { createAsyncThunk } from '@reduxjs/toolkit';
import { Layout } from 'store/layout/interfaces';

export const getLayoutData = createAsyncThunk(
  'layout/getLayoutData',
  async (_, thunkAPI) => {
    const response = await fetch('/layout.json');

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue('Missing layout data.');
    }

    return thunkAPI.fulfillWithValue(data as Layout);
  },
);

export default getLayoutData;
