import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

interface ChatMessageBoxProps {
  isUserMessage?: boolean;
}

const ChatMessageBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isUserMessage',
})<ChatMessageBoxProps>(({ theme, isUserMessage }) => ({
  padding: '0.625rem',
  margin: '0.313rem',
  borderRadius: '0.313rem',
  maxWidth: '48.5rem',
  width: '100%',
  fontSize: '1rem',
  lineHeight: '1.5rem',

  backgroundColor: isUserMessage
    ? theme.palette.background.shark
    : theme.palette.background.comet,
  color: isUserMessage
    ? theme.palette.background.cadetBlue
    : theme.palette.text.lightWhite,
  alignSelf: isUserMessage ? 'flex-start' : 'flex-end',
  marginRight: isUserMessage ? 'initial' : 0,
  marginLeft: isUserMessage ? 0 : 'initial',
}));

export default ChatMessageBox;
