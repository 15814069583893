import React from 'react';
import ReactDOM from 'react-dom/client';
import 'styles/reset.css';
import App from 'App';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'config/mui/theme';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import store from 'store';
import 'event-source-polyfill';
import AppSnackbar from 'comoponents/AppSnackbar/AppSnackbar';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
        <AppSnackbar />
      </ThemeProvider>
    </HelmetProvider>
  </Provider>,
);
