import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginBottom: '0.5rem',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '2rem',
  backgroundColor: theme.palette.background.chat,
  boxShadow: `0 0 3rem ${theme.palette.background.blue}`,
  border: '0.1rem solid rgba(39, 45, 54, 1)',
  padding: '1.3rem 1rem',

  [theme.breakpoints.up('lg')]: {
    padding: '1.3rem 1.1rem',
    borderRadius: '2.2rem',
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '6rem',
    background:
      'linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
    zIndex: 1,
    pointerEvents: 'none',
    borderRadius: '2.5rem',
  },

  form: {
    width: '100%',
    alignItems: 'center',
  },

  '#chatArea::-webkit-scrollbar': {
    display: 'none',
  },

  '#chatArea': {
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
}));

export default StyledBox;
