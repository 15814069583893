import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const ChatBox = styled(Box)(() => ({
  height: '40vh',
  overflowY: 'auto',
  display: 'flex',
  marginBottom: '1.5rem',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
}));

export default ChatBox;
