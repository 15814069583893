import React from 'react';
import { Typography, Grid } from '@mui/material';
import background from 'assets/backgrounds/patternbg.svg';
import BottomSectionItem from 'comoponents/BottomSection/BottomSectionItem/BottomSectionItem';
import { useAppSelector } from 'store/hooks';
import layout from 'store/layout/selectors';
import theme from 'config/mui/theme';

export default function BottomSection() {
  const bottomSection = useAppSelector(layout)?.sections.bottomSection;

  return (
    <Grid
      container
      justifyContent="center"
      py={{ xs: 4, sm: 8, md: 10 }}
      px={2}
      sx={{
        backgroundSize: 'cover',
        backgroundImage: `
          radial-gradient(100% 100% at 50% 0%, #1C2026 0%, rgba(0, 0, 0, 0) 100%),
          url(${background})`,
        backgroundBlendMode: 'overlay',
      }}
    >
      <Grid item sm={10} xl={9} xxl={7} mb={{ xs: 6, sm: 10, lg: 14 }}>
        <Typography
          variant="h1"
          textAlign="center"
          width="100%"
          sx={{
            whiteSpace: 'pre-line',
            backgroundClip: 'text',
            background: `${theme.palette.text.twoTone}`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {bottomSection?.title}
        </Typography>
      </Grid>
      <Grid item sm={10} xl={9} xxl={7}>
        <Grid container justifyContent="space-between">
          {bottomSection?.bottomSectionItems.map((item) => (
            <BottomSectionItem
              key={`pain-point-${item.content}`}
              content={item.content}
              value={item.value}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
