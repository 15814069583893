import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addMessageToThread,
  createAssistantThread,
  sendChatMessages,
  sendInitialNotification,
} from 'store/chat/thunks';
import defaultState from 'store/chat/defaultState';
import {
  AppNotificationPayload,
  AppNotificationType,
} from 'store/chat/interfaces';

export const chat = createSlice({
  name: 'chat',
  initialState: defaultState,
  reducers: {
    setIsRequestPending: (draft, action) => {
      draft.isRequestPending = action.payload;
    },
    showNotification: (
      draft,
      action: PayloadAction<AppNotificationPayload>,
    ) => {
      const { type, message } = action.payload;
      draft.notification.type = type;
      draft.notification.message = message;
      draft.notification.isOpen = true;
    },

    hideNotification: (draft) => {
      draft.notification.type = undefined;
      draft.notification.message = '';
      draft.notification.isOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendInitialNotification.fulfilled, (draft, action) => {
      console.info(action.payload);
      draft.isRequestPending = false;
    });
    builder.addCase(sendInitialNotification.rejected, (draft, action) => {
      chat.caseReducers.showNotification(draft, {
        payload: {
          type: AppNotificationType.error,
          message: (action.payload as string) || 'An error occurred',
        },
        type: 'showNotification',
      });
      draft.isRequestPending = false;
    });
    builder.addCase(sendInitialNotification.pending, (draft) => {
      draft.isRequestPending = true;
    });

    builder.addCase(sendChatMessages.fulfilled, (draft) => {
      draft.isRequestPending = false;
    });
    builder.addCase(sendChatMessages.rejected, (draft, action) => {
      chat.caseReducers.showNotification(draft, {
        payload: {
          type: AppNotificationType.error,
          message: (action.payload as string) || 'An error occurred',
        },
        type: 'showNotification',
      });
      draft.isRequestPending = false;
    });
    builder.addCase(sendChatMessages.pending, (draft) => {
      draft.isRequestPending = true;
    });

    builder.addCase(createAssistantThread.fulfilled, (draft, action) => {
      draft.thread = action.payload;
      draft.isRequestPending = false;
    });
    builder.addCase(createAssistantThread.rejected, (draft, action) => {
      chat.caseReducers.showNotification(draft, {
        payload: {
          type: AppNotificationType.error,
          message: (action.payload as string) || 'An error occurred',
        },
        type: 'showNotification',
      });
      draft.isRequestPending = false;
    });
    builder.addCase(createAssistantThread.pending, (draft) => {
      draft.isRequestPending = true;
    });

    builder.addCase(addMessageToThread.rejected, (draft, action) => {
      chat.caseReducers.showNotification(draft, {
        payload: {
          type: AppNotificationType.error,
          message: (action.payload as string) || 'An error occurred',
        },
        type: 'showNotification',
      });
    });
  },
});

export const { setIsRequestPending, showNotification, hideNotification } =
  chat.actions;
export default chat.reducer;
