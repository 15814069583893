import React from 'react';
import { Grid, CardMedia, Typography, Link } from '@mui/material';
import SocialMediaLinks from 'comoponents/Footer/SocialMediaLinks/SocialMediaLinks';
import CompanyInfo from 'comoponents/Footer/CompanyInfo/CompanyInfo';
import { useAppSelector } from 'store/hooks';
import layout from 'store/layout/selectors';

function Footer() {
  const footer = useAppSelector(layout)?.footer;

  return (
    <Grid
      container
      justifyContent="center"
      px={2}
      py={{ xs: 4, sm: 8, md: 10 }}
    >
      <Grid item sm={10} xl={9} xxl={7}>
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            px={{ md: 2 }}
            pb={3}
            display="flex"
            flexDirection="column"
            alignItems={{ xs: 'center', sm: 'flex-start' }}
          >
            <CardMedia
              component="img"
              loading="lazy"
              src="/logo.svg"
              alt="logo"
              sx={{ maxWidth: '11rem' }}
            />
            <SocialMediaLinks />
          </Grid>
          <Grid item xs={12} md={4} lg={3} px={{ md: 2 }} pb={3} gap={3}>
            {footer?.privacyPolicy && (
              <Link
                href={footer.privacyPolicy.url}
                target="_blank"
                rel="nofollow noopener"
                sx={{ textDecoration: 'none' }}
              >
                <Typography variant="h3" mb={1} color="text.primary">
                  {footer.privacyPolicy.label}
                </Typography>
              </Link>
            )}
            {footer?.terms && (
              <Link
                href={footer.terms.url}
                target="_blank"
                rel="nofollow noopener"
                sx={{ textDecoration: 'none' }}
              >
                <Typography variant="h3" mb={1} color="text.primary">
                  {footer.terms.label}
                </Typography>
              </Link>
            )}
            {footer?.contactUs && (
              <Link
                href={footer.contactUs.url}
                target="_blank"
                rel="nofollow noopener"
                sx={{ textDecoration: 'none' }}
              >
                <Typography variant="h3" mb={1} color="text.primary">
                  {footer.contactUs.label}
                </Typography>
              </Link>
            )}
          </Grid>
          <Grid item xs={12} md={4} lg={3} px={{ md: 2 }}>
            <Typography variant="h4" color="text.lightGrey" mb={5}>
              {footer?.content}
            </Typography>
            <CompanyInfo
              vat={footer?.vat}
              regon={footer?.regon}
              krs={footer?.krs}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Footer;
