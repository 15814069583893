export enum ApiType {
  completions = 'completions',
  assistants = 'assistants',
}

export interface CompletionsConfig {
  initialNotificationUrl: string;
  chatUrl: string;
  gtmId?: string;
}

export interface AssistantsConfig {
  assistantUrls: {
    createThread: string;
    addMessage: string;
    runThread: string;
  };
  token: string;
  conversationId: string;
  isoLanguageCode: string;
  gtmId?: string;
}

export interface Config {
  apiType: ApiType;
  config: CompletionsConfig | AssistantsConfig;
}

export interface ConfigState {
  config: Config | null;
}
