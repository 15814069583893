import React from 'react';
import { Dot } from 'comoponents/ChatSection/Chat/LoadingDots/LoadingDots.styles';
import { Box } from '@mui/material';

function LoadingDots() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        py: 1,
        position: 'absolute',
        bottom: '4.6rem',
        right: '2rem',
      }}
    >
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
    </Box>
  );
}

export default LoadingDots;
