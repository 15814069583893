import React from 'react';
import { AppBar, CardMedia } from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import theme from 'config/mui/theme';

export default function NavBar() {
  return (
    <AppBar position="static">
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          [theme.breakpoints.up('md')]: {
            justifyContent: 'start',
          },
        }}
      >
        <Box>
          <CardMedia
            component="img"
            loading="lazy"
            src="/logo.svg"
            alt="logo"
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
