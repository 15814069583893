import { styled, keyframes } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const threeDotsLoader = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

export const Dot = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  width: '0.7rem',
  height: '0.7rem',
  margin: '0.3rem',
  backgroundColor: theme.palette.background.blue,
  borderRadius: '50%',
  border: `0.1rem solid ${theme.palette.text.primary}`,
  animation: `${threeDotsLoader} 1.4s infinite ease-in-out both`,

  '&:nth-of-type(1)': {
    animationDelay: '-0.08s',
  },

  '&:nth-of-type(2)': {
    animationDelay: '-0.16s',
  },

  '&:nth-of-type(3)': {
    animationDelay: '-0.24s',
  },

  '&:nth-of-type(4)': {
    animationDelay: '-0.32s',
  },

  '&:nth-of-type(5)': {
    animationDelay: '-0.40s',
  },
}));
