import React, { useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { useAnimatedValue } from 'hooks/useAnimatedValue';

interface BottomSectionItemProps {
  content: string;
  value: number;
}

export default function BottomSectionItem({
  content,
  value,
}: BottomSectionItemProps) {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const { value: animatedValue, setValue: setAnimatedValue } = useAnimatedValue(
    {
      initialValue: 0,
      targetValue: value,
      step: 1,
      interval: 10,
      shouldAnimate: inView,
    },
  );

  useEffect(() => {
    if (!inView) {
      setAnimatedValue(0);
    }
  }, [inView, setAnimatedValue]);

  return (
    <Grid item xs={12} md={4} lg={3} ref={ref} px={{ md: 2 }}>
      <Typography variant="body1" textAlign="center">
        {Math.round(animatedValue)}%
      </Typography>
      <Typography
        variant="body2"
        textAlign="center"
        color="text.secondary"
        sx={{ whiteSpace: 'pre-line', my: 3 }}
      >
        {content}
      </Typography>
    </Grid>
  );
}
