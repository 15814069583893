const colors = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  RED: '#E63946',
  GREEN: '#39E689',
  CHAT_BG: '#0b0e12',
  TEXT_LIGHT: '#75839B',
  TEXT_LIGHT_WHITE: '#F6F7F9',
  TEXT_LIGHT_GREY: '#C2C9D2',
  DIVIDER: 'rgba(28, 32, 38, 0.5)',
  LINEAR_GRADIENT: 'linear-gradient(180deg, #F6F7F9 0%, #75839B 100%)',
  PRUSSIAN_BLUE: '#2A4F7F',
  SHARK: '#1C2026',
  COMET: '#505B6D',
  CADET_BLUE: '#a9b3c0',
};

export default colors;
