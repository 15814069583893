interface SectionItems {
  content: string;
  value: number;
  resultType: ResultTypes;
  arrowDir: ArrowDirs;
}

export enum ResultTypes {
  positive = 'positive',
  negative = 'negative',
}

export enum ArrowDirs {
  up = 'up',
  down = 'down',
}

export interface Layout {
  theme: string;
  meta: {
    title: string;
    description: string;
  };
  navbar: {
    logoUrl: string;
  };
  sections: {
    chatSection: {
      title: string;
      initialMessage: string;
      inputPlaceholder: string;
    };
    middleSection: {
      title: string;
      middleSectionItems: SectionItems[];
    };
    bottomSection: {
      title: string;
      bottomSectionItems: SectionItems[];
    };
  };
  footer: {
    socials: {
      linkedIn: string;
      instagram: string;
      facebook: string;
    };
    content: string;
    contactUs: {
      url: string;
      label: string;
    };
    privacyPolicy: {
      url: string;
      label: string;
    };
    terms: {
      url: string;
      label: string;
    };
    vat: string;
    regon: string;
    krs: string;
  };
}

export interface LayoutState {
  layout: Layout | null;
}
