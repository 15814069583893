import React, { useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import { NorthEast, SouthEast } from '@mui/icons-material';
import { useInView } from 'react-intersection-observer';
import { useAnimatedValue } from 'hooks/useAnimatedValue';
import colors from 'config/mui/colors';
import theme from 'config/mui/theme';
import { ArrowDirs, ResultTypes } from 'store/layout/interfaces';

interface MiddleSectionItemProps {
  content: string;
  value: number;
  resultType: string;
  arrowDir: string;
  isLast: boolean;
  isFirst: boolean;
}

export default function MiddleSectionItem({
  content,
  value,
  resultType,
  arrowDir,
  isLast,
  isFirst,
}: MiddleSectionItemProps) {
  const { ref, inView } = useInView({
    threshold: 0.5, // Trigger when 50% of the element is in view
    triggerOnce: true,
  });

  const { value: animatedValue, setValue: setAnimatedValue } = useAnimatedValue(
    {
      initialValue: 0,
      targetValue: value,
      step: 1,
      interval: 10,
      shouldAnimate: inView,
    },
  );

  const isPositive = resultType === ResultTypes.positive;
  const isArrowUp = arrowDir === ArrowDirs.up;
  const color = isPositive ? colors.GREEN : colors.RED;

  useEffect(() => {
    if (!inView) {
      setAnimatedValue(0);
    }
  }, [inView, setAnimatedValue]);

  return (
    <Grid
      container
      py={{ xs: 3, md: 5 }}
      sx={{
        borderBottom: isLast ? 'none' : `0.1rem solid ${theme.palette.divider}`,
        pt: { lg: isFirst ? 0 : 5 },
      }}
      ref={ref}
    >
      <Grid
        item
        xs={2}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Typography
          variant="subtitle1"
          color={isPositive ? 'text.green' : 'text.red'}
        >
          {Math.round(animatedValue)}%
        </Typography>
        {isArrowUp ? (
          <NorthEast sx={{ color }} />
        ) : (
          <SouthEast sx={{ color }} />
        )}
      </Grid>
      <Grid item xs={10} md={9} pl={3}>
        <Typography
          variant="h2"
          color="text.primary"
          sx={{ whiteSpace: 'pre-line' }}
        >
          {content}
        </Typography>
      </Grid>
    </Grid>
  );
}
