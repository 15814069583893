import React, { useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import Navbar from 'comoponents/Navbar/Navbar';
import ChatSection from 'comoponents/ChatSection/ChatSection';
import Footer from 'comoponents/Footer/Footer';
import MiddleSection from 'comoponents/MiddleSection/MiddleSection';
import BottomSection from 'comoponents/BottomSection/BottomSection';
import Head from 'comoponents/Head/Head';
import background from 'assets/backgrounds/background-ai.svg';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import layout from 'store/layout/selectors';
import getLayoutData from 'store/layout/thunks';
import config from 'store/config/selectors';
import getConfigData from 'store/config/thunks';

export default function App() {
  const layoutData = useAppSelector(layout);
  const configData = useAppSelector(config);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!layoutData) {
      dispatch(getLayoutData());
    }
  }, [dispatch, layoutData]);

  useEffect(() => {
    if (!configData) {
      dispatch(getConfigData());
    }
  }, [dispatch, configData]);

  if (!layoutData || !configData) {
    return (
      <Box
        width="100%"
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Head />
      <Box
        sx={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Navbar />
        <ChatSection />
      </Box>
      <MiddleSection />
      <BottomSection />
      <Footer />
    </>
  );
}
