import React from 'react';

export default function FacebookIcon() {
  return (
    <svg
      width="10"
      height="20"
      viewBox="0 0 10 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.55277 20V10.6154H0V7.23652H2.55277V4.3505C2.55277 2.08264 4.0607 0 7.53529 0C8.9421 0 9.98237 0.1311 9.98237 0.1311L9.9004 3.28642C9.9004 3.28642 8.83949 3.27638 7.68178 3.27638C6.42879 3.27638 6.22804 3.83768 6.22804 4.7693V7.23652H10L9.83588 10.6154H6.22804V20H2.55277Z"
        fill="#3F4756"
      />
    </svg>
  );
}
