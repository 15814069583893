import DOMPurify from 'dompurify';

function formatChatResponse(content: string) {
  return DOMPurify.sanitize(
    content
      .replace(/\n\n/g, '<br><br>')
      .replace(/\n/g, '<br>')
      .replace(/\*/g, '')
      .replace(/#/g, ''),
  );
}

export default formatChatResponse;
