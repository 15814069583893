import { createSlice } from '@reduxjs/toolkit';
import defaultState from 'store/config/defaultState';
import { getConfigData } from 'store/config/thunks';

export const config = createSlice({
  name: 'layout',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConfigData.fulfilled, (draft, action) => {
      draft.config = action.payload;
    });
  },
});

export default config.reducer;
