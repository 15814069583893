import React from 'react';
import { Typography, Grid } from '@mui/material';
import { useAppSelector } from 'store/hooks';
import layout from 'store/layout/selectors';
import MiddleSectionItem from 'comoponents/MiddleSection/MiddleSectionItem/MiddleSectionItem';
import theme from 'config/mui/theme';

function MiddleSection() {
  const middleSection = useAppSelector(layout)?.sections.middleSection;

  return (
    <Grid
      container
      justifyContent="center"
      py={{ xs: 2, sm: 8, md: 10, lg: 12, xl: 15 }}
      px={2}
    >
      <Grid
        item
        sm={10}
        lg={5}
        xl={4.5}
        xxl={3.5}
        mb={{ xs: 2, md: 8 }}
        pr={{ lg: 8 }}
      >
        <Typography
          variant="h1"
          sx={{
            whiteSpace: 'pre-line',
            textAlign: { xs: 'center', lg: 'left' },
            backgroundClip: 'text',
            background: `${theme.palette.text.twoTone}`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {middleSection?.title}
        </Typography>
      </Grid>
      <Grid item sm={10} lg={5} xl={4.5} xxl={3.5}>
        {middleSection?.middleSectionItems.map((item, index) => (
          <MiddleSectionItem
            key={`kpi-${item.content}`}
            content={item.content}
            value={item.value}
            resultType={item.resultType}
            arrowDir={item.arrowDir}
            isLast={
              index === (middleSection?.middleSectionItems?.length ?? 0) - 1
            }
            isFirst={index === 0}
          />
        ))}
      </Grid>
    </Grid>
  );
}

export default MiddleSection;
