import React from 'react';
import { Grid, Typography } from '@mui/material';

interface CompanyInfoProps {
  vat?: string;
  regon?: string;
  krs?: string;
}

export default function CompanyInfo({ vat, regon, krs }: CompanyInfoProps) {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Typography variant="h5" color="text.secondary">
          VAT-ID
        </Typography>
        <Typography variant="h4" color="text.lightGrey">
          {vat || 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h5" color="text.secondary">
          REGON
        </Typography>
        <Typography variant="h4" color="text.lightGrey">
          {regon || 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h5" color="text.secondary">
          KRS
        </Typography>
        <Typography variant="h4" color="text.lightGrey">
          {krs || 'N/A'}
        </Typography>
      </Grid>
    </Grid>
  );
}
