import React from 'react';

export default function InstagramIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4833 9.33333C12.4833 7.58333 11.0833 6.18333 9.33333 6.18333C7.58333 6.18333 6.18333 7.58333 6.18333 9.33333C6.18333 11.0833 7.58333 12.4833 9.33333 12.4833C11.0833 12.4833 12.4833 11.0833 12.4833 9.33333ZM14.1167 9.33333C14.1167 12.0167 12.0167 14.1167 9.33333 14.1167C6.65 14.1167 4.55 12.0167 4.55 9.33333C4.55 6.65 6.65 4.55 9.33333 4.55C12.0167 4.55 14.1167 6.65 14.1167 9.33333ZM15.4 4.31667C15.4 5.01667 14.9333 5.48333 14.2333 5.48333C13.5333 5.48333 13.0667 5.01667 13.0667 4.31667C13.0667 3.61667 13.5333 3.15 14.2333 3.15C14.9333 3.15 15.4 3.73333 15.4 4.31667ZM9.33333 1.63333C7.93333 1.63333 5.01667 1.51667 3.85 1.98333C3.03333 2.33333 2.33333 3.03333 2.1 3.85C1.63333 5.01667 1.75 7.93333 1.75 9.33333C1.75 10.7333 1.63333 13.65 2.1 14.8167C2.33333 15.6333 3.03333 16.3333 3.85 16.5667C5.01667 17.0333 8.05 16.9167 9.33333 16.9167C10.6167 16.9167 13.65 17.0333 14.8167 16.5667C15.6333 16.2167 16.2167 15.6333 16.5667 14.8167C17.0333 13.5333 16.9167 10.6167 16.9167 9.33333C16.9167 8.05 17.0333 5.01667 16.5667 3.85C16.3333 3.03333 15.6333 2.33333 14.8167 2.1C13.65 1.51667 10.7333 1.63333 9.33333 1.63333ZM18.6667 9.33333V13.1833C18.6667 14.5833 18.2 15.9833 17.15 17.15C16.1 18.2 14.7 18.6667 13.1833 18.6667H5.48333C4.08333 18.6667 2.68333 18.2 1.51667 17.15C0.583333 16.1 0 14.7 0 13.1833V9.33333V5.48333C0 3.96667 0.583333 2.56667 1.51667 1.51667C2.68333 0.583333 4.08333 0 5.48333 0H13.1833C14.5833 0 15.9833 0.466667 17.15 1.51667C18.0833 2.56667 18.6667 3.96667 18.6667 5.48333V9.33333Z"
        fill="#3F4756"
      />
    </svg>
  );
}
