import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AddMessageToThreadPayload,
  CreateAssistantThreadPayload,
  EventText,
  EventType,
  MessageRole,
  RunThreadStreamPayload,
  SendChatMessagesPayload,
  SendInitialNotificationPayload,
  Thread,
  ThreadEvent,
} from 'store/chat/interfaces';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { setIsRequestPending } from 'store/chat/chat';

function isTextDeltaEvent(
  event: any,
): event is ThreadEvent<EventType.TextDelta> {
  return event?.type === EventType.TextDelta && typeof event?.data === 'string';
}

export const sendInitialNotification = createAsyncThunk(
  'chat/sendInitialNotification',
  async (payload: SendInitialNotificationPayload, thunkAPI) => {
    const response = await fetch(payload.url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: payload.user,
        messages: payload.messages,
        host: window.location.hostname,
      }),
    });

    if (!response.ok) {
      return thunkAPI.rejectWithValue('Initial notification send error');
    }
    return thunkAPI.fulfillWithValue('Initial notification sent');
  },
);

export const sendChatMessages = createAsyncThunk(
  'chat/sendChatMessages',
  async (payload: SendChatMessagesPayload, thunkAPI) => {
    const response = await fetch(payload.url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: payload.user,
        messages: payload.messages,
        host: window.location.hostname,
      }),
    });

    if (!response.ok) {
      return thunkAPI.rejectWithValue('Chat messages sending error');
    }

    return response.body?.getReader();
  },
);

export const createAssistantThread = createAsyncThunk(
  'chat/createAssistantThread',
  async (payload: CreateAssistantThreadPayload, thunkAPI) => {
    const response = await fetch(payload.url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payload.token}`,
      },
      body: JSON.stringify({
        conversationId: payload.conversationId,
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue('Create assistant thread error');
    }

    return thunkAPI.fulfillWithValue(data as Thread);
  },
);

export const addMessageToThread = createAsyncThunk(
  'chat/addMessageToThread',
  async (payload: AddMessageToThreadPayload, thunkAPI) => {
    const response = await fetch(
      payload.url.replace(':threadId', payload.threadId),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${payload.token}`,
        },
        body: JSON.stringify({
          content: payload.content,
          conversationId: payload.conversationId,
        }),
      },
    );

    if (!response.ok) {
      return thunkAPI.rejectWithValue('Add message to thread error');
    }

    return thunkAPI.fulfillWithValue('Message added to thread');
  },
);

export const runThreadStream = createAsyncThunk(
  'chat/runThreadStream',
  async (payload: RunThreadStreamPayload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsRequestPending(true));

      const eventSource = new EventSourcePolyfill(
        payload.url
          .replace(':threadId', payload.threadId)
          .replace(':conversationId', payload.conversationId)
          .replace(':isoLanguageCode', payload.isoLanguageCode),
        { headers: { Authorization: `Bearer ${payload.token}` } },
      );

      eventSource.addEventListener(EventType.TextDelta, (event) => {
        if (!isTextDeltaEvent(event)) {
          return;
        }

        const { delta } = JSON.parse(event.data) as EventText;

        payload.setMessagesCallback((prevMessages) => {
          const lastMessage = prevMessages[prevMessages.length - 1];

          if (lastMessage && lastMessage.role === MessageRole.Assistant) {
            return [
              ...prevMessages.slice(0, -1),
              {
                ...lastMessage,
                content: lastMessage.content + (delta?.value || ''),
              },
            ];
          }
          return [
            ...prevMessages,
            { role: MessageRole.Assistant, content: delta?.value || '' },
          ];
        });

        payload.scrollToCallback();
      });

      eventSource.addEventListener(EventType.TextDone, () => {
        thunkAPI.dispatch(setIsRequestPending(false));
        payload.scrollToCallback();
        eventSource.close();
      });

      eventSource.addEventListener(EventType.Error, () => {
        thunkAPI.dispatch(setIsRequestPending(false));
        eventSource.close();
      });

      eventSource.addEventListener(EventType.End, () => {
        thunkAPI.dispatch(setIsRequestPending(false));
        eventSource.close();
      });

      eventSource.onerror = () => {
        thunkAPI.dispatch(setIsRequestPending(false));
        eventSource.close();
      };
    } catch (error) {
      thunkAPI.dispatch(setIsRequestPending(false));
    }
  },
);
