import { AssistantsConfig, Config } from 'store/config/interfaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import config from 'store/config/selectors';
import layout from 'store/layout/selectors';
import { useEffect, useMemo, useState } from 'react';
import { ChatMessage, MessageRole } from 'store/chat/interfaces';
import {
  addMessageToThread,
  createAssistantThread,
  runThreadStream,
} from 'store/chat/thunks';
import { assistantThread } from 'store/chat/selectors';

interface AssistantChatLogicProps {
  scrollToBottomFn: () => void;
  skip?: boolean;
}

function isAssistantsConfig(cfg?: Config['config']): cfg is AssistantsConfig {
  return (
    (cfg as AssistantsConfig)?.token !== undefined &&
    (cfg as AssistantsConfig)?.conversationId !== undefined &&
    typeof (cfg as AssistantsConfig)?.assistantUrls === 'object' &&
    (cfg as AssistantsConfig)?.assistantUrls?.createThread !== undefined &&
    (cfg as AssistantsConfig)?.assistantUrls?.addMessage !== undefined &&
    (cfg as AssistantsConfig)?.assistantUrls?.runThread !== undefined
  );
}

function useAssistantChatLogic({
  scrollToBottomFn,
  skip,
}: AssistantChatLogicProps) {
  const initialMessage =
    useAppSelector(layout)?.sections.chatSection.initialMessage;

  const thread = useAppSelector(assistantThread);
  const configData = useAppSelector(config);
  const assistantConfig = useMemo(() => {
    if (configData && isAssistantsConfig(configData?.config)) {
      return {
        ...configData.config,
        conversationId:
          localStorage.getItem('convId') || configData.config.conversationId,
      };
    }
    return null;
  }, [configData]);

  const dispatch = useAppDispatch();

  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [initialMessageAdded, setInitialMessageAdded] = useState(false);

  useEffect(() => {
    if (!initialMessageAdded && initialMessage && !skip) {
      setInitialMessageAdded(true);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: MessageRole.Assistant,
          content: initialMessage,
        },
      ]);
    }
  }, [initialMessageAdded, initialMessage, skip]);

  useEffect(() => {
    if (thread || !assistantConfig || skip) {
      return;
    }

    dispatch(
      createAssistantThread({
        url: assistantConfig.assistantUrls.createThread,
        token: assistantConfig.token,
        conversationId: assistantConfig.conversationId,
      }),
    );
  }, [assistantConfig, dispatch, skip, thread]);

  const addThreadMessage = async () => {
    if (!thread?.id || !assistantConfig || skip) {
      return;
    }

    setMessages((prevMessages) => [
      ...prevMessages,
      { role: MessageRole.User, content: message.trim() },
    ]);

    dispatch(
      addMessageToThread({
        url: assistantConfig?.assistantUrls.addMessage,
        token: assistantConfig?.token,
        threadId: thread.id,
        conversationId: assistantConfig?.conversationId,
        content: message,
      }),
    );

    setMessage('');
  };

  const runThread = async () => {
    if (!thread?.id || !assistantConfig || skip) {
      return;
    }

    dispatch(
      runThreadStream({
        url: assistantConfig.assistantUrls.runThread,
        token: assistantConfig.token,
        threadId: thread.id,
        conversationId: assistantConfig?.conversationId,
        isoLanguageCode: assistantConfig?.isoLanguageCode,
        setMessagesCallback: setMessages,
        scrollToCallback: scrollToBottomFn,
      }),
    );
  };

  return {
    message,
    messages,
    setMessage,
    addThreadMessage,
    runThread,
  };
}

export default useAssistantChatLogic;
