import React, { useRef, useState } from 'react';
import { Box, Grid, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import ChatBox from 'comoponents/ChatSection/Chat/styled/ChatBox.styled';
import StyledBox from 'comoponents/ChatSection/Chat/styled/StyledBox.styled';
import { MessageRole } from 'store/chat/interfaces';
import ChatMessageBox from 'comoponents/ChatSection/Chat/styled/ChatMessageBox.styled';
import LoadingDots from 'comoponents/ChatSection/Chat/LoadingDots/LoadingDots';
import useCompletionChatLogic from 'hooks/useCompletionChatLogic';
import { useAppSelector } from 'store/hooks';
import { isRequestPending } from 'store/chat/selectors';
import config from 'store/config/selectors';
import { ApiType } from 'store/config/interfaces';
import formatChatResponse from 'utils/formatChatResponse';
import useAssistantChatLogic from 'hooks/useAssistantChatLogic';
import layout from 'store/layout/selectors';

export default function Chat() {
  const isPending = useAppSelector(isRequestPending);
  const configData = useAppSelector(config);
  const chatSection = useAppSelector(layout)?.sections.chatSection;
  const chatAreaRef = useRef<HTMLDivElement | null>(null);

  const [isFirstUserMessageSent, setIsFirstUserMessageSent] = useState(false);

  const scrollToBottom = () => {
    const chatArea = chatAreaRef?.current;
    if (!chatArea) {
      return;
    }
    chatArea.scrollTop = chatArea.scrollHeight;
  };

  const {
    message: completionMessage,
    messages: completionMessages,
    setMessage: setCompletionMessage,
    sendMessages: sendCompletionMessages,
  } = useCompletionChatLogic({
    scrollToBottomFn: scrollToBottom,
    skip: configData?.apiType !== ApiType.completions,
  });

  const {
    message: assistantMessage,
    messages: assistantMessages,
    setMessage: setAssistantMessage,
    addThreadMessage,
    runThread,
  } = useAssistantChatLogic({
    scrollToBottomFn: scrollToBottom,
    skip: configData?.apiType !== ApiType.assistants,
  });

  const message =
    configData?.apiType === ApiType.completions
      ? completionMessage
      : assistantMessage;
  const messages =
    configData?.apiType === ApiType.completions
      ? completionMessages
      : assistantMessages;
  const setMessage =
    configData?.apiType === ApiType.completions
      ? setCompletionMessage
      : setAssistantMessage;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (isPending || !message) {
      return;
    }

    if (configData?.apiType === ApiType.completions) {
      await sendCompletionMessages();
      return;
    }

    await addThreadMessage();
    await runThread();

    if (!isFirstUserMessageSent && window?.dataLayer) {
      setIsFirstUserMessageSent(true);
      window.dataLayer.push({ event: 'first_user_message_sent' });
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <StyledBox>
          <ChatBox>
            <Box
              id="chatArea"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '40vh',
                overflowY: 'auto',
                borderRadius: '0.313rem',
              }}
              ref={chatAreaRef}
            >
              {messages.map((chatMessage) => (
                <ChatMessageBox
                  isUserMessage={chatMessage.role === MessageRole.User}
                  key={chatMessage.role + chatMessage.content}
                  dangerouslySetInnerHTML={{
                    __html: formatChatResponse(chatMessage.content),
                  }}
                />
              ))}
              {isPending && <LoadingDots />}
            </Box>
          </ChatBox>
          <form id="messageForm" autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              fullWidth
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder={chatSection?.inputPlaceholder}
              autoFocus
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SendIcon
                      onClick={handleSubmit}
                      sx={{ cursor: 'pointer' }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </StyledBox>
      </Grid>
    </Grid>
  );
}
