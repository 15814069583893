import { combineReducers } from '@reduxjs/toolkit';
import layout from 'store/layout/layout';
import chat from 'store/chat/chat';
import config from 'store/config/config';

export default combineReducers({
  layout,
  config,
  chat,
});
