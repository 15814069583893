import React, { useEffect, useState } from 'react';

interface AnimatedValue {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}

interface UseAnimatedValueOptions {
  initialValue: number;
  targetValue: number;
  step: number;
  interval: number;
  shouldAnimate: boolean;
}

export function useAnimatedValue({ initialValue, targetValue, step, interval, shouldAnimate }: UseAnimatedValueOptions): AnimatedValue {
  const [animatedValue, setAnimatedValue] = useState<number>(initialValue);

  useEffect(() => {
    let animationInterval: NodeJS.Timeout;

    const startAnimation = () => {
      let currentValue = initialValue;
      animationInterval = setInterval(() => {
        currentValue += step;
        setAnimatedValue(() => Math.min(currentValue, targetValue));
      }, interval);
    };

    if (shouldAnimate) {
      startAnimation();
    } else {
      setAnimatedValue(initialValue);
    }
    return () => {
      clearInterval(animationInterval);
    };
  }, [initialValue, targetValue, step, interval, shouldAnimate]);

  return {
    value: animatedValue,
    setValue: setAnimatedValue
  };
}

export default useAnimatedValue;
