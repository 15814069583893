import { createTheme } from '@mui/material';
import colors from 'config/mui/colors';

export const theme = createTheme({
  palette: {
    mode: 'dark',
    text: {
      primary: colors.WHITE,
      secondary: colors.TEXT_LIGHT,
      red: colors.RED,
      green: colors.GREEN,
      lightWhite: colors.TEXT_LIGHT_WHITE,
      lightGrey: colors.TEXT_LIGHT_GREY,
      twoTone: colors.LINEAR_GRADIENT,
    },
    background: {
      default: colors.BLACK,
      chat: colors.CHAT_BG,
      blue: colors.PRUSSIAN_BLUE,
      shark: colors.SHARK,
      comet: colors.COMET,
      cadetBlue: colors.CADET_BLUE,
    },
    divider: colors.DIVIDER,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      xxl: 2500,
    },
  },
  typography: {
    fontFamily: 'Outfit, Arial, sans-serif',
    h1: {
      fontSize: '2rem',
      lineHeight: '3rem',
      fontWeight: '600',
      '@media (min-width: 600px)': {
        fontSize: '3rem',
        lineHeight: '4rem',
      },
    },
    h2: {
      fontSize: '1rem',
      lineHeight: '2rem',
      fontWeight: '400',
      '@media (min-width: 600px)': {
        fontSize: '2rem',
        lineHeight: '2.75rem',
      },
    },
    h3: {
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
      fontWeight: '600',
    },
    h4: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: '600',
    },
    h5: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: '450',
    },
    subtitle1: {
      fontSize: '1.5rem',
      lineHeight: '3rem',
      fontWeight: '600',
      '@media (min-width: 600px)': {
        fontSize: '2.5rem',
      },
    },
    body1: {
      fontSize: '3rem',
      lineHeight: '3.5rem',
      fontWeight: '600',
      '@media (min-width: 600px)': {
        fontSize: '4rem',
      },
    },
    body2: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: '400',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: '3rem',
          width: '8rem',
          borderRadius: '2.2rem',
          textTransform: 'none',
          letterSpacing: '0.04rem',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            fontSize: '1rem',
            borderRadius: '1rem',
            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: `0.1rem solid ${colors.WHITE}`,
              },
            },
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          minHeight: '8rem',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          backgroundImage: 'none',
          boxShadow: 'none',
          borderBottom: 'none',
          color: 'inherit',
        },
      },
    },
  },
});

export default theme;
