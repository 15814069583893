import React from 'react';
import { Link, SvgIcon, Box, Grid } from '@mui/material';
import layout from 'store/layout/selectors';
import { useAppSelector } from 'store/hooks';
import FacebookIcon from 'comoponents/Footer/SocialMediaLinks/Icons/FacebookIcon';
import InstagramIcon from 'comoponents/Footer/SocialMediaLinks/Icons/InstagramIcon';
import LinkedInIcon from 'comoponents/Footer/SocialMediaLinks/Icons/LinkedInIcon';

export default function SocialMediaLinks() {
  const socials = useAppSelector(layout)?.footer?.socials;

  if (!socials) {
    return null;
  }

  return (
    <Grid container sx={{ justifyContent: { xs: 'center', sm: 'flex-start' } }}>
      <Box display="flex" justifyContent="space-between" gap={4}>
        <Link href={socials?.facebook} target="_blank" rel="nofollow noopener">
          <SvgIcon component={FacebookIcon} />
        </Link>
        <Link href={socials?.instagram} target="_blank" rel="nofollow noopener">
          <SvgIcon component={InstagramIcon} />
        </Link>
        <Link href={socials?.linkedIn} target="_blank" rel="nofollow noopener">
          <SvgIcon component={LinkedInIcon} />
        </Link>
      </Box>
    </Grid>
  );
}
