import React from 'react';
import { useAppSelector } from 'store/hooks';
import layout from 'store/layout/selectors';
import { Helmet } from 'react-helmet-async';
import config from 'store/config/selectors';

function Head() {
  const layoutData = useAppSelector(layout);
  const configData = useAppSelector(config);

  return (
    <Helmet>
      <title>{layoutData?.meta.title}</title>
      <meta name="description" content={layoutData?.meta.description} />
      {configData?.config?.gtmId && (
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${configData.config.gtmId}');`}
        </script>
      )}
    </Helmet>
  );
}

export default Head;
