import { createSlice } from '@reduxjs/toolkit';
import defaultState from 'store/layout/defaultState';
import { getLayoutData } from 'store/layout/thunks';

export const layout = createSlice({
  name: 'layout',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLayoutData.fulfilled, (draft, action) => {
      draft.layout = action.payload;
    });
  },
});

export default layout.reducer;
