import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { sendChatMessages, sendInitialNotification } from 'store/chat/thunks';
import { ChatMessage, MessageRole } from 'store/chat/interfaces';
import config from 'store/config/selectors';
import layout from 'store/layout/selectors';
import readStream from 'utils/readStream';
import { CompletionsConfig, Config } from 'store/config/interfaces';

interface CompletionChatLogicProps {
  scrollToBottomFn: () => void;
  skip?: boolean;
}

function isCompletionsConfig(cfg?: Config['config']): cfg is CompletionsConfig {
  return (
    (cfg as CompletionsConfig)?.initialNotificationUrl !== undefined &&
    (cfg as CompletionsConfig)?.chatUrl !== undefined
  );
}

function useCompletionChatLogic({
  scrollToBottomFn,
  skip,
}: CompletionChatLogicProps) {
  const initialMessage =
    useAppSelector(layout)?.sections.chatSection.initialMessage;

  const configData = useAppSelector(config);
  const completionsConfig =
    configData && isCompletionsConfig(configData?.config)
      ? configData.config
      : null;

  const dispatch = useAppDispatch();

  const [uniqueID, setUniqueID] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [initialMessageAdded, setInitialMessageAdded] = useState(false);

  useEffect(() => {
    if (!uniqueID || !initialMessage || !completionsConfig || skip) {
      return;
    }

    dispatch(
      sendInitialNotification({
        user: uniqueID,
        url: completionsConfig.initialNotificationUrl,
        messages: [
          {
            role: MessageRole.Assistant,
            content: initialMessage,
          },
        ],
      }),
    );
  }, [dispatch, initialMessage, completionsConfig, skip, uniqueID]);

  useEffect(() => {
    if (uniqueID) {
      return;
    }
    setUniqueID(`${Date.now()}-${uuidv4()}`);
  }, [uniqueID]);

  useEffect(() => {
    if (!initialMessageAdded && initialMessage && !skip) {
      setInitialMessageAdded(true);
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: MessageRole.Assistant, content: initialMessage },
      ]);
    }
  }, [initialMessage, initialMessageAdded, skip]);

  const prepareMessagesRequest = (
    messagesAmount: number,
    newMessage: string,
  ) => {
    const truncatedMessages = messages.slice(-messagesAmount);
    truncatedMessages.push({ role: MessageRole.User, content: newMessage });

    return truncatedMessages;
  };

  const sendMessages = async () => {
    if (!message || !completionsConfig || skip) {
      return;
    }

    setMessages((prevMessages) => [
      ...prevMessages,
      { role: MessageRole.User, content: message.trim() },
    ]);

    const messagesToSend = prepareMessagesRequest(6, message.trim());

    setMessage('');

    try {
      const reader = await dispatch(
        sendChatMessages({
          user: uniqueID,
          messages: messagesToSend,
          url: completionsConfig.chatUrl,
        }),
      ).unwrap();

      if (reader) {
        await readStream(reader, setMessages, scrollToBottomFn);
      }
    } catch {
      console.error('Failed to send message');
    }
  };

  return {
    message,
    messages,
    setMessage,
    sendMessages,
  };
}

export default useCompletionChatLogic;
