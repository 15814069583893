import React from 'react';
import { AssistantsConfig } from 'store/config/interfaces';

export enum MessageRole {
  User = 'user',
  Assistant = 'assistant',
}

export interface ChatMessage {
  role: MessageRole;
  content: string;
}

export interface SendInitialNotificationPayload {
  url: string;
  user: string;
  messages: ChatMessage[];
}

export interface SendChatMessagesPayload {
  url: string;
  user: string;
  messages: ChatMessage[];
}

export interface ChatState {
  isRequestPending: boolean;
  thread: Thread | null;
  notification: AppNotification;
}

export interface CreateAssistantThreadPayload {
  url: string;
  token: string;
  conversationId: AssistantsConfig['conversationId'];
}

export interface Thread {
  id: string;
}

export interface AddMessageToThreadPayload {
  url: string;
  token: string;
  threadId: Thread['id'];
  conversationId: AssistantsConfig['conversationId'];
  content: string;
}

export interface RunThreadStreamPayload {
  url: string;
  token: string;
  threadId: Thread['id'];
  conversationId: AssistantsConfig['conversationId'];
  isoLanguageCode: string;
  setMessagesCallback: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
  scrollToCallback: () => void;
}

export enum EventType {
  RunStepCreated = 'runStepCreated',
  RunStepDelta = 'runStepDelta',
  RunStepDone = 'runStepDone',
  MessageCreated = 'messageCreated',
  MessageDelta = 'messageDelta',
  MessageDone = 'messageDone',
  TextCreated = 'textCreated',
  TextDelta = 'textDelta',
  TextDone = 'textDone',
  Error = 'error',
  End = 'end',
}

export interface ThreadEvent<T extends EventType> {
  type: T;
  data: string;
}

export interface EventText {
  delta?: {
    value?: string;
  };
}

export interface AppNotification {
  type?: AppNotificationType;
  message: string;
  isOpen: boolean;
}

export interface AppNotificationPayload {
  type?: AppNotificationType;
  message: string;
}

export enum AppNotificationType {
  error = 'error',
  info = 'info',
  warning = 'warning',
  success = 'success',
}
