import { createAsyncThunk } from '@reduxjs/toolkit';
import { Config } from 'store/config/interfaces';

export const getConfigData = createAsyncThunk(
  'config/getConfigData',
  async (_, thunkAPI) => {
    const response = await fetch('/config.json');

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue('Missing config data.');
    }

    return thunkAPI.fulfillWithValue(data as Config);
  },
);

export default getConfigData;
