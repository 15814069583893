import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { chatNotifications } from 'store/chat/selectors';
import { Snackbar, Alert, Typography } from '@mui/material';
import { hideNotification } from 'store/chat/chat';

function AppSnackbar() {
  const { type, isOpen, message } = useAppSelector(chatNotifications);
  const dispatch = useAppDispatch();

  return type ? (
    <Snackbar
      open={isOpen}
      onClose={() => dispatch(hideNotification())}
      autoHideDuration={5000}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      sx={{ maxWidth: '35rem' }}
    >
      <Alert
        severity={type}
        sx={{
          display: 'flex',
          alignItems: 'center',
          border: '0.1rem solid',
          borderColor: 'inherit',
        }}
      >
        <Typography variant="body2">{message}</Typography>
      </Alert>
    </Snackbar>
  ) : null;
}

export default AppSnackbar;
