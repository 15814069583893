import React from 'react';
import { Typography, Grid } from '@mui/material';
import Chat from 'comoponents/ChatSection/Chat/Chat';
import { useAppSelector } from 'store/hooks';
import layout from 'store/layout/selectors';
import theme from 'config/mui/theme';

function ChatSection() {
  const chatSection = useAppSelector(layout)?.sections.chatSection;

  return (
    <Grid container justifyContent="center" px={2} pb={5} pt={{ xs: 1, lg: 3 }}>
      <Grid item sm={10} xl={9} xxl={7} textAlign="center">
        <Typography
          variant="h1"
          sx={{
            whiteSpace: 'pre-line',
            backgroundClip: 'text',
            background: `${theme.palette.text.twoTone}`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {chatSection?.title}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={10} xl={9} xxl={7} mt={{ xs: 8, lg: 10 }}>
        <Chat />
      </Grid>
    </Grid>
  );
}

export default ChatSection;
